import React from "react";
import { PinkSquares0, PinkSquares1, PinkCircles1 } from "@assets/Background";
import { PageLayout, TrustedBy } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import { GetADemo } from "@components/GetADemo/GetADemo";
import { StaticImage } from "gatsby-plugin-image";
import { pinkText } from "@components/Typography/Typography";
import ImageAndBulletPoints from "@components/ImageAndBulletPoints/ImageAndBulletPoints";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";
import { useWindowSize } from "@helpers/Hooks";
import LearnMoreSection from "@components/LearnMoreSection/LearnMoreSection";
import Button, { ArrowLinkButton } from "@components/Button_new/Button";

export default function Startups() {
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "startups" }}>
			<Demo />
			<InventoryManagement />
			<GenemodNotebook />
			<PartnerWithAccelerators />
			<StartupSuccess />
			<TrustedBy />
			<LearnMoreSection textColor="text-dark" background="pinkFade" />
		</PageLayout>
	);
}

const Demo = () => (
	<BackgroundFadeContainer type="blueFade" skewBottom withHeaderPadding>
		<GetADemo
			titleText="The operating system that scales your startup"
			subtitleText="Genemod provides startups with low-cost, easy-to-use lab automation software needed to scale life sciences R&D. Contact us today to see if your startup qualifies."
			linkColor="ui_01"
			imageNode={
				<StaticImage
					loading="eager"
					src={"../../../assets/products/freezer-create-item.png"}
					alt="freezer item creation example"
				/>
			}
		/>
	</BackgroundFadeContainer>
);

const InventoryManagement = () => (
	<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
		<BackgroundShapes leftShape={{ shape: <PinkCircles1 />, yOffset: 420 }}>
			<SectionTitle
				title={pinkText`All the features you need for ${"inventory management"}`}
				textColor="text-dark"
				titleWidth={800}
				marginBottom={60}
				marginBottomTablet={12}
			/>
			<ImageAndBulletPoints
				bullets={[
					{
						title: "We’ve built your favorite Excel-like features to make data entry painless",
						text: "Keep track of item types, concentrations, attachments, and notes of any samples and reagents stored in your lab.",
					},
					{
						title: pinkText`Create ${"custom item types"} or choose from our templates`,
						text: "Default item templates include primer, strain, antibody, chemical, enzyme, cell line, and more.",
					},
					{
						title: "Generate automations for 100+ use cases",
						text: "Create automations that alert you when items are running low or when updates have been made.",
					},
				]}
				image={
					<StaticImage
						loading="eager"
						src="../../../assets/products/Freezer_box.png"
						alt="freezer box example page"
						width={786}
						height={586}
					/>
				}
				withImageShadowMargins
				bulletStyles={{
					titleVariant: "HEADER4",
					titleColor: "text-dark",
					textVariant: "SUBHEADERDESC",
					textColor: "text-dark",
					containerWidth: 476,
				}}
				imagePosition="left"
			/>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);

const GenemodNotebook = () => (
	<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
		<BackgroundShapes
			rightShape={{ shape: <PinkCircles1 />, yOffset: 340 }}
		>
			<SectionTitle
				title={pinkText`${"Genemod Notebook"} takes R&D collaboration to a whole other level`}
				textColor="text-dark"
				titleWidth={800}
				marginBottom={60}
				marginBottomTablet={60}
			/>
			<ImageAndBulletPoints
				bullets={[
					{
						title: "Collaborative real-time editing",
						text: "We take teamwork to the next level by letting you and your team work together in real time on experiments.",
					},
					{
						title: "Create, comment, and reply to protocols",
						text: "Make it a team effort with in-line and page comments and replies.",
					},
					{
						title: pinkText`Centralize ${"attachments and metadata"}`,
						text: "Easily upload and manage relevant attachments within experiments, as well as create new sections.",
					},
				]}
				image={
					<StaticImage
						loading="eager"
						src="../../../assets/products/exp_editor.png"
						alt="screenshot of genemods lab notebook software"
						width={786}
						height={586}
					/>
				}
				withImageShadowMargins
				bulletStyles={{
					titleVariant: "HEADER4",
					titleColor: "text-dark",
					textVariant: "SUBHEADERDESC",
					textColor: "text-dark",
					containerWidth: 476,
				}}
			/>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);

const PartnerWithAccelerators = () => (
	<BackgroundFadeContainer type="blueFade" skewTop skewBottom>
		<SectionTitle
			title={pinkText`We ${"partner"} with accelerators, incubators, and VCs`}
			subtitle="Ask your organization if they offer Genemod for Startups. Set your company up for success from day one."
			textColor="ui-01"
			titleWidth={650}
			subtitleWidth={640}
		/>
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				marginBottom: 36,
			}}
		>
			<ArrowLinkButton color="brand" link="CONTACT_US">
				Contact us
			</ArrowLinkButton>
		</div>
		<ImageAndBulletPoints
			width={966}
			bullets={[
				{
					title: pinkText`Introducing ${"Genemod Activate"}`,
					text: "Genemod Activate is a startup program for portfolio companies that have raised up to Series A rounds of funding.",
				},
				{
					title: "Where research teams get work done",
					text: "We’ve built the first platform for life sciences R&D where project management meets electronic lab notebook.",
				},
			]}
			image={
				<StaticImage
					loading="eager"
					src="../../../assets/products/ROI-graph.png"
					alt="3rd party, heap estimate of savings by using genemod"
					width={441}
					height={477}
				/>
			}
			bulletStyles={{
				titleVariant: "HEADER4",
				titleColor: "ui-01",
				textVariant: "SUBHEADERDESC",
				textColor: "ui-01",
				containerWidth: 496,
			}}
		/>
	</BackgroundFadeContainer>
);

const StartupSuccess = () => {
	const { isMobile } = useWindowSize();

	return (
		<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
			<BackgroundShapes
				leftShape={{
					shape: <PinkSquares0 />,
					xOffset: 0,
					yOffset: 80,
				}}
				rightShape={{
					shape: <PinkSquares1 />,
					xOffset: 140,
					yOffset: 250,
				}}
			>
				<SectionTitle
					title={pinkText`Set your startup up for ${"success"} from day one`}
					subtitle="We bring your team’s work together in one shared space. Improve process management by leaving the repetitive stuff to us so you can do more of the work you do best."
					textColor="text-dark"
					titleWidth={976}
					subtitleWidth={926}
					marginBottom={24}
					marginBottomTablet={24}
					marginBottomMobile={24}
				/>

				{isMobile && (
					<StaticImage
						loading="eager"
						style={{ width: "100%" }}
						src="../../../assets/products/onboard-workflow-diagram-vertical.png"
						alt="diagram showing the onboarding to genemod process"
					/>
				)}
				{!isMobile && (
					<StaticImage
						loading="eager"
						style={{ width: "100%" }}
						src="../../../assets/products/onboard-workflow-diagram.png"
						alt="diagram showing the onboarding to genemod process"
					/>
				)}
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
};
